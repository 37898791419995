var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("CbNumStatistics", {
        staticClass: "Statistics",
        attrs: { "is-tabs": true, "statistics-info": _vm.statisticsInfo },
        on: { changeTab: _vm.changeTab },
      }),
      _c(
        "appForm",
        {
          attrs: {
            "to-list": _vm.toList,
            "screen-roster-list": _vm.screenRosterList,
            "search-placeholder": "搜索员工/工号",
            width: "400",
          },
          on: {
            getSearchCallback: _vm.getSearchCallback,
            screenRosterParams: _vm.screenRosterParams,
            exportFileList: _vm.exportFileList,
          },
        },
        [
          _vm.isNotDetail
            ? _c(
                "template",
                { slot: "customButton" },
                [
                  _c(
                    "a-space",
                    [
                      _vm.selectType === 0 || _vm.selectType === 1
                        ? _c(
                            "a-button",
                            {
                              attrs: { prefix: "piliangdaoru" },
                              on: { click: _vm.salary },
                            },
                            [_vm._v(" 批量定薪 ")]
                          )
                        : _vm._e(),
                      _vm.selectType === 0
                        ? _c(
                            "a-button",
                            {
                              attrs: { prefix: "piliangdaoru" },
                              on: { click: _vm.adjustment },
                            },
                            [_vm._v(" 批量调薪 ")]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        2
      ),
      _c("custom-table", {
        attrs: {
          columns: _vm.columns,
          "data-source": _vm.dataSource,
          "row-key": "onJobId",
          "offset-bottom": 60,
          pagination: {
            current: _vm.pager.pageNum,
            pageSize: _vm.pager.pageSize,
            total: _vm.total,
            showTotal: function (total) {
              return "共 " + total + " 条数据"
            },
            showQuickJumper: true,
            showSizeChanger: true,
          },
        },
        on: { change: _vm.loadDataList },
        scopedSlots: _vm._u([
          {
            key: "index",
            fn: function (ref) {
              var scope = ref.scope
              return [_c("span", [_vm._v(_vm._s(scope.index + 1))])]
            },
          },
          {
            key: "staffName",
            fn: function (ref) {
              var scope = ref.scope
              return [
                _c("CbTableAvater", {
                  attrs: {
                    "table-avater": scope.record.avatar,
                    "table-name": scope.record.staffName,
                    "on-job-id": scope.record.onJobId,
                    "staff-id": scope.record.staffId,
                  },
                }),
              ]
            },
          },
          {
            key: "operation",
            fn: function (ref) {
              var scope = ref.scope
              return [
                _c(
                  "span",
                  { staticClass: "different" },
                  [
                    scope.record.isAdjustment === 0
                      ? _c(
                          "a-button",
                          {
                            attrs: { type: "link" },
                            on: {
                              click: function ($event) {
                                return _vm.fixedWage(scope.record)
                              },
                            },
                          },
                          [_vm._v("定薪")]
                        )
                      : _vm._e(),
                    scope.record.isAdjustment === 1
                      ? _c(
                          "a-button",
                          {
                            attrs: { type: "link" },
                            on: {
                              click: function ($event) {
                                return _vm.changeWage(scope.record)
                              },
                            },
                          },
                          [_vm._v("调薪")]
                        )
                      : _vm._e(),
                    scope.record.isAdjustment === 1
                      ? _c(
                          "a-button",
                          {
                            attrs: { type: "link" },
                            on: {
                              click: function ($event) {
                                return _vm.changeWageHistory(scope.record)
                              },
                            },
                          },
                          [_vm._v("调薪历史")]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ]
            },
          },
        ]),
      }),
      _c("ChangeWage", {
        ref: "ChangeWage",
        attrs: { info: _vm.selectTableItemInfo },
        on: { complate: _vm.updateList },
      }),
      _c("SalaryHistory", { ref: "SalaryHistory" }),
      _c("FixedWage", {
        ref: "FixedWage",
        attrs: { info: _vm.selectTableItemInfo },
        on: { complate: _vm.updateList },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }